<template>
  <VueSidePanel v-model="showPanel" side="right" width="450px" hide-close-btn :no-close="true">
    <template #header>
      <div class="panel-header">
        <label class="panel-header_label">Filtros de sucursales</label>
        <button type="button" class="d-none d-lg-block panel-header_button clean-button btn btn-secondary" @click.prevent="cleanFilters()">Limpiar</button>
        <button type="button" class="d-none d-lg-block panel-header_button apply-button btn btn-success" @click.prevent="applyFilters()">Aplicar</button>
      </div>
    </template>
    <template #default>
      <div class="panel-content">
        <div class="category form-check" v-for="category in filterOptions" :key="category" @click.prevent="selectCategory(category.slug)">
          <input class="form-check-input" type="radio" :value="category.slug" v-model="selectedFilters.type" :id="category.title">
          <label class="category-title form-check-label" :for="category.title">
            <span v-if="category.image">
              <img class="category-image" :src="'https://tvaisucursales-16905.kxcdn.com/public-assets' + category.image">
            </span>
            {{ category.title }}
          </label>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-sm-block d-md-block d-lg-none">
        <div class="panel-footer">
          <button type="button" class="panel-header_button clean-button btn btn-secondary" @click.prevent="cleanFilters()">Limpiar</button>
          <button type="button" class="panel-header_button apply-button btn btn-success" @click.prevent="applyFilters()">Aplicar</button>
        </div>
      </div>
    </template>
  </VueSidePanel>
</template>

<script>
import { VueSidePanel } from 'vue3-side-panel';
import 'vue3-side-panel/dist/vue3-side-panel.css';

export default {
  props: {
    showPanel: { type: Boolean, default: false },
    categories: { type: Array, required: true },
    selectedCategories: { type: Array, required: true }
  },
  emits: ["applyClicked"],
  components: { VueSidePanel },
  data() {
    return {
      selectedFilters: {
        slugs: [],
        ids: [],
        type: 'all'
      }
    }
  },
  watch: {
    selectedCategories: {
      async handler(newVal) {
        if (newVal) {
          this.selectedFilters.type = newVal
        }
      },
      immediate: true,
    },
  },
  computed: {
    filterOptions() {
      return this.categories
    }
  },
  methods: {
    cleanFilters() {
      this.selectedFilters.type = 'all';
      this.applyFilters();
    },
    applyFilters() {
      this.$emit('applyClicked', this.selectedFilters);
    },
    selectCategory(categorySlug) {
      this.selectedFilters.type = categorySlug;
    },
    addCategory(categorySlug) {
      this.selectedFilters.slugs.push(categorySlug);
    },
    removeCategory(categorySlug) {
      const index = this.selectedFilters.slugs.findIndex(cat => cat === categorySlug);
      this.selectedFilters.slugs.splice(index, 1);
    }
  }
}
</script>

<style>
.panel-header {
  align-items: center;
  background: #efefef;
  display: flex;
  gap: 1em;
  height: 70px;
  padding: 1em;
}
.panel-header_label {
  flex: 2;
}
.panel-header_button {
  flex: 1;
  min-width: 100px;
}
.panel-header_label {
  color: black;
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
}
.panel-content {
  display: flex;
  flex-direction: column;
  padding: 1em;
}
.category {
  display: flex;
  gap: 2em;
  padding-top: 1em;
}
.category-title {
  color: #A4A4A4;
  font-size: 16px;
  font-style: normal;
}
.category-image {
  height: 32px;
  margin-right: 1em;
  width: 32px;
}
.panel-footer {
  display: flex;
  gap: 1em;
  padding: 1em 1em 1em 1em;
}
.clean-button {
  background: #efefef;
  border-color: #6c757d;
  color: #6c757d;
}
.apply-button {
  background: #43b02a;
}
.btn {
  height: 40px;
}
</style>