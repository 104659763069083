import { v4 as uuidv4 } from 'uuid';

export default {
  inject: ['$axios'],
  methods: {
    saveStatistics(statType, searchData) {
      // Check geolocation permission
      let uuid = localStorage.getItem('tvai_uuid') || '';
      let payload = {
        uuid: uuid,
        type: statType,
        search: searchData?.search,
        search_coordinates: searchData?.search_coordinates,
        zip_code: searchData?.zip_code,
        is_permission_granted: this.isPermissionGranted,
        user_coordinates: this.freezedUserLocationCoords
      }
      this.$axios.put(`/api/v1/locations/save_stats`, {data: payload})
      .then(response => {
      })
    },
    generateUserIdentifier() {
      let uuid = localStorage.getItem('tvai_uuid');
      if (!uuid) {
        localStorage.setItem('tvai_uuid', uuidv4());
      }
    }
  }
}