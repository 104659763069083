<template>
  <div class="list-item" :class="{'selected': !isMobileVersion && selectedLocation?._id?.$oid === location._id.$oid, 'mobile': isMobileVersion}" @click="selectLocation(location)">
    <label class="list-item_title">
      <svg v-if="favorites.includes(location._id.$oid)"  @click.prevent="setFavorite(location)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#43B02A"     class="bi bi-star-fill" viewBox="0 0 16 16">
        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" v-else @click.prevent="setFavorite(location)" width="16" height="16" fill="#43B02A" class="bi bi-star" viewBox="0 0 16 16">
        <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
      </svg>
      {{ location.title }}
      <span>({{ distance }})</span></label>
    <div class="list-item_info">
      <label class="info-title" v-if="!isMobileVersion">Dirección:</label>
      <p class="info-description">{{ location.address }}</p>
      <a v-if="isMobileVersion" class="btn mobile-maps_buttons btn-light" :href="generateGoogleURL(location.coordinates, userLocationCoords)" target="_blank">
        <span>
          <img src="https://tvaisucursales-16905.kxcdn.com/public-assets/google-icon" class="item-button_image">
        </span>
      </a>
      <a v-if="isMobileVersion" class="btn mobile-maps_buttons btn-light" :href="generateWazeURL(location.coordinates, userLocationCoords)" target="_blank">
        <span>
          <img src="https://tvaisucursales-16905.kxcdn.com/public-assets/waze-icon" class="item-button_image">
        </span>
      </a>
    </div>
    <div class="list-item_info" v-if="location.schedule">
      <label class="info-title" v-if="!isMobileVersion">Horario:</label>
      <p class="info-description" v-for="record in location.schedule" :key="record">
        {{ record.days }} de {{ formatStringTime(record.open_time) }} - {{ formatStringTime(record.close_time) }}
        <span v-if="location.atm"><br>Cajero (ATM): {{formatAtmText(location.atm, record)}}</span>
      </p>
    </div>
    <div class="list-item_buttons" v-if="!isMobileVersion">
      <a class="btn btn-secondary list-item_button" @click="saveStatistics('google_clicked', null)" :href="generateGoogleURL(location.coordinates, userLocationCoords)" target="_blank" role="button">
        <span>
          <img src="https://tvaisucursales-16905.kxcdn.com/public-assets/google-icon" class="item-button_image">
        </span>
        Ir con maps
      </a>
      <a class="btn btn-secondary list-item_button" @click="saveStatistics('waze_clicked', null)" :href="generateWazeURL(location.coordinates, userLocationCoords)" target="_blank" role="button">
        <span>
          <img src="https://tvaisucursales-16905.kxcdn.com/public-assets/waze-icon" class="item-button_image">
        </span>
        Ir con waze
      </a>
    </div>
  </div>
</template>

<script>
import mapMixin from '../../../mixins/mapMixin';
import statsMixin from '../../../mixins/statsMixin';

export default {
  props: {
    selectedLocation: { type: Object },
    location: { type: Object, required: true },
    userLocationCoords: { type: Array, required: true },
    freezedUserLocationCoords: { type: Array, required: true },
    isMobileVersion: { type: Boolean, default: false }
  },
  mixins: [mapMixin, statsMixin],
  data() {
    return {
      favorites: [],
      isPermissionGranted: false
    }
  },
  mounted() {
    const codedStorage = localStorage.getItem('tvai_favorites');
    const storedLocations = codedStorage ? codedStorage.split(',') : [];
    this.favorites = storedLocations?.length > 0 ? storedLocations : [];
    // Check if user allow geolocation
    navigator.geolocation.watchPosition(() => {
      this.isPermissionGranted = true;
    });
  },
  computed: {
    distance() {
      let distance = 'N/A';
      if (this.location && this.userLocationCoords) {
        let locationCoords = this.location['coordinates'];
        distance = this.calculateDistanceInKm(this.userLocationCoords[1],this.userLocationCoords[0],locationCoords[1],locationCoords[0]);
      }
      return `${distance}Km`;
    }
  },
  methods: {
    selectLocation(location) {
      this.$emit('locationClicked', location);
    },
    formatStringTime(time) {
      let temp = time.substr(0,5);
      temp = temp.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (temp.length > 1) { // If time format correct
        temp = temp.slice (1);  // Remove full string match value
        temp[5] = +temp[0] < 12 ? 'am' : 'pm'; // Set AM/PM
        temp[0] = +temp[0] % 12 || 12; // Adjust hours
      }
      return temp.join ('');
    },
    formatAtmText(stringTime, schedule) {
      if(!stringTime || stringTime === "" || !schedule){
        return false
      } else {
        if(stringTime.includes("12")){
          return `${this.formatStringTime(schedule.open_time)} - ${this.formatStringTime(schedule.close_time)}`;
        } else {
          return `${stringTime.replace('hrs','horas')} abierto`
        }
      }
    },
    calculateDistanceInKm(userLatitude,userLongitude,locationLatitude,locationLongitude) {
      let earthRadius = 6371;
      let latituteDistance = this.deg2rad(locationLatitude-userLatitude);
      let longitudeDistance = this.deg2rad(locationLongitude-userLongitude);
      let a = Math.sin(latituteDistance/2) * Math.sin(latituteDistance/2) + Math.cos(this.deg2rad(userLatitude)) * Math.cos(this.deg2rad(locationLatitude)) * Math.sin(longitudeDistance/2) * Math.sin(longitudeDistance/2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      let distanceResult = earthRadius * c;
      return distanceResult.toFixed(2);
    },
    deg2rad(deg) {
        return deg * (Math.PI/180)
    },
    setFavorite(location) {
      if (!this.favorites.includes(location._id.$oid)) {
        this.favorites.push(location._id.$oid);
        localStorage.setItem('tvai_favorites', this.favorites.toString())
      } else {
        const index = this.favorites.indexOf(location._id.$oid);
        this.favorites.splice(index, 1);
        localStorage.setItem('tvai_favorites', this.favorites.toString())
      }
    },
  }
}
</script>

<style scoped>
.list-item {
  border-radius: 3px;
  box-shadow: rgba(0,0,0,.1019607843) 0px 5px 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  padding: 10px;
}
.mobile {
  min-width: 365px!important;
}
.list-item_title {
  align-content: center;
  color: #282828;
  display: flex;
  gap: 5px;
  font-family: "Nunito Sans",sans-serif;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  margin-bottom: 1em;
}
.list-item_title span {
  font-weight: normal!important;
}
.list-item_info {
  display: flex;
  flex-grow: 1;
  font-size: 14px!important;
  font-style: normal!important;
  justify-content: flex-start;
  gap: 1em;
}
.info-title {
  color: #8d8d8d;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  min-width: 55px;
}
.info-description {
  color: #282828;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize !important;
}
.list-item_buttons {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  z-index: 999!important;
}
.list-item_button {
  align-content: center;
  background: #efefef;
  border: none;
  border-radius: 50px;
  color: #4e4e4e;
  display: flex;
  font-size: 13px;
  gap: 10px;
  width: 50%;
  justify-content: center;
}
.selected {
  border: solid 3px #43b029 !important;
}
.item-button_image {
  height: 22px;
  width: 22px;
}
.mobile-maps_buttons {
  align-items: center;
  background: #EFEFEF;
  border-radius: 30px;
  display: flex;
  height: 40px!important;
  justify-content: center;
  width: 40px!important;
}
.bi-star {
  cursor: pointer;
}
</style>